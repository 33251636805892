
section.ctas {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#235a8d+0,173a5b+100 */
    background: #235a8d; /* Old browsers */
    background: -moz-linear-gradient(top,  #235a8d 0%, #173a5b 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  #235a8d 0%,#173a5b 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  #235a8d 0%,#173a5b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#235a8d', endColorstr='#173a5b',GradientType=0 ); /* IE6-9 */

    .img-hover-zoom {
        border-radius: 8px;
    }
    .img-hover-zoom img {
        transition: all .3s ease-in-out;
        border-radius: 8px;
    }
    .mod-row {
        max-width: 1675px;
        margin: 0 auto;
    }
    .mod {
        max-width: 480px;
        
        .h4 {
            font-family: lato, sans-serif;
            font-size: 21px;
            color: #ffffff;
            letter-spacing: 0.15em !important;
            font-weight: 700;
            transition: 0.3s;
        }
        a:hover {
            box-shadow: 0 0 6px rgba($color: #000000, $alpha: 0.5);

            .img-hover-zoom img {
                transform: scale(1.02);
                // filter: grayscale(1);
            }
            .opacity-85 {
                opacity: .5 !important;
            }

            .hover-circle figure::before {
                -webkit-animation: circle .75s;
                animation: circle .75s;
            }
            figure+span {
                bottom: -36px;
                opacity: 1;
            }
            .h4 {
                color: #f0f0f0;
            }
        }
        /* Circle */
        .hover-circle figure {
            position: relative;
        }
        .hover-circle figure::before {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 2;
            display: block;
            content: '';
            width: 0;
            height: 0;
            background: rgba($color: #ffffff, $alpha: 0.2);
            border-radius: 100%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            opacity: 0;
        }
        @-webkit-keyframes circle {
            0% {
                opacity: 1;
            }
            40% {
                opacity: 1;
            }
            100% {
                width: 200%;
                height: 200%;
                opacity: 0;
            }
        }
        @keyframes circle {
            0% {
                opacity: 1;
            }
            40% {
                opacity: 1;
            }
            100% {
                width: 200%;
                height: 200%;
                opacity: 0;
            }
        }
    }
}

section.aaha {
    p {
        line-height: 32px;
    }
}

section.main {
    .mod1 {
        margin-left: 150px;
    }
    p {
        line-height: 32px;
    }

    /********************************************************************************
    * Video Tile Component
    ********************************************************************************/
    .video-tile {
        position: relative;
        display: inline-block;
        cursor: pointer;
        width: 100%;
        height: auto;
    
        &:hover:before {
            opacity: 1;
        }
        
        img {
            width: 100%;
        }
    
        .curtain {
            // background-color: #000;
            opacity: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            transition: opacity .5s ease-in;
            z-index: 1;
        }
    
        // .video-tile__title {
        //     position: absolute;
        //     bottom: 0;
        //     left: 0;
        //     right: 0;
        //     text-overflow: ellipsis;
        //     font: 500 24px/.9 "Oswald", sans-serif;
        //     text-transform: uppercase;
        //     color: #fff;
        //     padding: 15px;
        //     background: #000;
        //     background: linear-gradient(0deg, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0) 100%);
        //     text-shadow: 2px 2px 4px rgba(39,40,41,.9);
        //     text-overflow: ellipsis;
        //     width: 100%;
        
        //     @media only screen and (min-width: 480px) and (max-width: 767px) {
        //         font: 500 32px/.9 "Oswald", sans-serif;
        //         padding: 45px 40px 28px 40px;
        //     }
            
        //     @media only screen and (min-width: 992px) and (max-width: 1279px) {
        //         font: 500 36px/.9 "Oswald", sans-serif;
        //         padding: 45px 40px 28px 40px;
        //     }
            
        //     @media only screen and (min-width: 1280px) {
        //         font: 500 42px/.9 "Oswald", sans-serif;
        //         padding: 45px 40px 28px 40px;
        //     }
        // }
    
        .video-tile-loader {
            display: none;
            width: 100%;
            height: 100%;
            z-index: 2;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            border: none;
            transition: opacity .5s ease-in;
        }
    }
    
    /********************************************************************************
    * SVG Play Button
    ********************************************************************************/
    .play {
        width: 65px;
        height: 92px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transform-origin: center;
        z-index: 1;
        cursor: pointer;
        // border-radius: 100%;
        // box-shadow: 0 5px 20px rgba(0,0,0,.5);
        transition: top .1s ease-in-out, box-shadow .2s ease-in-out;
        
        &-button {
            width: 65px;
            height: 92px;
            // border-radius: 100%;
            // background-color: rgba(255, 255, 255, 0.25);
            padding: 0px;
            position: relative;
        
            .player-icon {
                fill: #fff;
            }
        }
        
        &-icon {
            position: absolute;
            width: 65px;
            height: 92px;
            top: 0;
            left: 0;
            opacity: 1;
            transition: width .2s ease-in-out, top .2s ease-in-out, left .2s ease-in-out, opacity .2s ease-in-out;
            
            .player-icon {
                fill: #ffffff;
                transition: fill .3s ease-in-out;
            }
        }
    }
    
    .video-tile:hover {
        .SVGobject {
            stroke-width: 92px;
        }
        
        .play {
            // box-shadow: 0 2px 15px rgba(0,0,0,.5);
            top: 49.25%;
        }
    
        .play-icon {
            opacity: 1;
        
            .player-icon {
                fill: $primary;
            }
        }
    }
    
    .SVGobject {
        stroke-width: 10px;
        stroke: none;
        transition: stroke-width 0.2s;
    }
    
    @keyframes stroke-width {
        0% { stroke-width: 10; }
        100% { stroke-width: 92; }
    }
  
}

section.associations {
    text-align: center;
    padding: 75px 0 150px;
}

section.hours {
    padding: 200px 0;

    h2 {
        font-size: 46px;
    }
    p {
        font-size: 20px;
        letter-spacing: 0.02em;
        line-height: 32px;
    }
    .btn {
        padding: 12px 30px;
    }
}

footer.foot {
    background-color: #17191A;

    br {
        display: none;
    }
    .info {
        .mod {
            letter-spacing: 0.02em;
            font-size: 24px;

            .brdr {
                border-right: 1px solid rgba(255,255,255,.2);
                font-size: 21px;
            }
            br {
                display: none;
            }
            .social {
                font-size: 18px;
            }
        }
    }
    .btm {
        color: #676B72;
        text-transform: uppercase;
        letter-spacing: 0.15em;
        font-size: 16px;
    }
}
.scroll-up {
    &:hover {
        box-shadow: none !important;
    }
}

.form-control {
    background-color: rgba($color: #E5EAEF, $alpha: 0.50);
}

.text-initial {
    text-transform: initial;
}

#ADAButton {
    line-height: 0 !important;
}
.btn {
    border-radius: 8px;
}
.btn-primary:hover {
    color: #ffffff !important;
    border-color: $secondary !important;
    background-color: $secondary !important;
}

#to-top{
    display:block;
    position:fixed;
    text-align:center;
    line-height:12px;
    right:17px;
    bottom:-30px;
    color:#fff;
    cursor:pointer;
    border-radius: 100%;
    z-index:9994;
    height:29px;
    width:29px;
    background-color:rgba(0,0,0,0.25);
    background-repeat:no-repeat;
    background-position:center;
    transition:background-color 0.1s linear;
    -webkit-transition:background-color 0.1s linear;
    &:hover {
      background-color: $primary;
    }
    &:after {
      background-color: $primary !important;
    }
}

#to-top{
  transition:box-shadow 0.3s cubic-bezier(.55,0,.1,1),background-color 0.1s linear;
  -webkit-transition:-webkit-box-shadow 0.3s cubic-bezier(.55,0,.1,1),background-color 0.1s linear;
  background-color:rgba(0,0,0,0.25) 
}

#to-top:hover, 
#to-top.dark:hover{
  transition:box-shadow 0.3s cubic-bezier(.55,0,.1,1),background-color 0.05s linear 0.25s;
  -webkit-transition:-webkit-box-shadow 0.3s cubic-bezier(.55,0,.1,1),background-color 0.05s linear 0.25s;
  box-shadow:1px 2px 3px rgba(0,0,0,0.16);
  background-color:transparent!important 
}
#to-top:after, 
#to-top:before{
    display:block;
    content:' ';
    height:100%;
    width:100%;
    position:absolute;
    top:0;
    left:0;
    z-index:1;
    background-color:#000;
    transform:scale(0);
    -webkit-transform:scale(0);
    transition:all 0.3s cubic-bezier(.55,0,.1,1);
    -webkit-transition:all 0.3s cubic-bezier(.55,0,.1,1);
    border-radius:100px;
    -webkit-border-radius:100px
}
#to-top:before{
  background-color:rgba(255,255,255,0.25);
  transform:scale(1);
  -webkit-transform:scale(1);
  transition:all 0.5s cubic-bezier(0.165,0.84,0.44,1);
  -webkit-transition:all 0.5s cubic-bezier(0.165,0.84,0.44,1);
  opacity:0;
  z-index:2
}

#to-top:hover:after{
  transform:scale(1);
  -webkit-transform:scale(1);
}
#to-top{
  overflow:hidden
}

#to-top i.fa-angle-up.top-icon, 
#to-top i.fa-angle-up{
  -webkit-transform:translate(0,0px);
  transform:translate(0,0px);
  transition:transform 0.2s ease;
  -webkit-transition:transform 0.2s ease;
}

#to-top:hover i.fa-angle-up.top-icon, 
#to-top:hover i.fa-angle-up, 
#to-top.hovered i.fa-angle-up.top-icon, 
#to-top.hovered i.fa-angle-up{
  -webkit-transform:translate(0,-29px);
  transform:translate(0,-29px)
}

#to-top:active:before{
  opacity:1
}

#to-top i{
  line-height:29px;
  width:29px;
  height:29px;
  font-size:14px;
  top:0;
  left:0;
  text-align:center;
  position:relative;
  z-index:10;
  background-color:transparent 
}
#to-top:hover, 
#to-top.dark:hover{
  background-color:#000
}
#to-top.dark{
  background-color:rgba(0,0,0,0.87)
}

//interior custom scss
body.interior {
    section[id*="interiorBanner"] {
        height: 600px;
        // min-height: 300px;
        @media screen and (max-width: 1600px) {
            & {
                height: 40vw;
            }
        }
    }

    &.page-type-3 {
        .card {
            padding: 60px 55px;
            background: #225688;
            color: #fff;
            border-radius: 8px;

            p.h3 {
                font-size: 26px;
                font-weight: 400;
                letter-spacing: 0.15em;
                text-transform: uppercase;
                margin-bottom: 20px;
            }
            [id*="HeaderInfoDiv"] {
                font-size: 14px;
                font-weight: 400;
                line-height: 17px;
                letter-spacing: 0.02em;
                margin-bottom: 30px !important;
            }
            label {
                display: none;
            }
            .form-control {
                background-color: rgba($color: #ffffff, $alpha: 1);
            }
            .text-muted {
                color: #ffffff !important;
            }
        }
    }
    &.page-type-12 {
        blockquote {
            font-size: 18px !important;
        }
    }
    &.page-type-26 {
        .page-content .container {
            max-width: 960px;
        }
    }
}

[class*="btn"]:not(.no-fx), .hover-fx {
    transition: 0.3s ease !important;
}
[class*="btn"]:not(.no-fx):not([class*="owl"]):hover, .hover-fx:not([class*="owl"]):hover {
    transform: translate3d(0, -2px, 0);
    box-shadow: 0px 13px 40px rgba($color: #000000, $alpha: 0.2);
}

.overflow-visible {
    overflow: visible !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

// Apointment Request Form

.list-group.mb-5 {
    >.list-group.mb-5 {
        >a:first-child {
            border-top-right-radius: 0 !important;
            border-top-left-radius: 0 !important;
        }

        ~a.list-group-item.list-group-item-action+a:nth-last-child(2),
        +a {
            border-top-right-radius: $border-radius !important;
            border-top-left-radius: $border-radius !important;
        }

        ~a.list-group-item.list-group-item-action+a:nth-last-child(3) {
            border-bottom-right-radius: $border-radius !important;
            border-bottom-left-radius: $border-radius !important;
            margin-bottom: 3rem;
        }
    }

    .wysiwyg>& {
        >a.list-group-item.list-group-item-action>.text-muted {
            font-weight: bold !important;
            color: $list-group-action-color !important;
        }
    }
}

.modal-open {
    overflow: auto;
}

.opacity-100 {
    opacity: 1 !important;
}

.opacity-90 {
    opacity: 0.9 !important;
}

.opacity-85 {
    opacity: 0.85 !important;
}

.opacity-75 {
    opacity: 0.75 !important;
}

.opacity-50 {
    opacity: 0.50 !important;
}

.opacity-25 {
    opacity: 0.25 !important;
}

.opacity-10 {
    opacity: 0.1 !important;
}

.opacity-0 {
    opacity: 0 !important;
}

.extra-small {
    font-size: $extra-small-font-size !important;
    line-height: $small-line-height !important;
}

.navbar-brand {
    font-family: $navbar-brand-font-family !important;
    white-space: normal;
}

blockquote {
    font-family: $blockquote-font-family;
    font-size: $blockquote-font-size;
    font-style: $blockquote-font-style;
    line-height: $blockquote-line-height;
    letter-spacing: $blockquote-letter-spacing;
    font-weight: $blockquote-font-weight;

    @media screen and (max-width: map-get($grid-breakpoints, md) - 1) {
        font-size: $font-size-lg !important;
        line-height: $lead-line-height !important;
    }
}

html {
    margin: 0 !important;
    overflow-x: hidden;
}

hr {
    width: 100%;
}

img {
    max-width: 100%;
    height: auto !important;
}

label {
    font-size: $label-font-size;
    line-height: $label-line-height;
    letter-spacing: $label-letter-spacing;
    font-weight: $label-font-weight;
    text-transform: $label-text-transform;
}

body {
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.02em;
    overflow-x: hidden;
    line-height: 1.6em;
    transition: opacity 0.85s ease 0.3s;
}

a {
    transition: 0.4s ease;
    text-decoration: none !important;
}

[data-brand*="fa-yelp"] {
    background-color: #af0606;
}

[data-brand*="fa-instagram"]:not([class*="bg-"]) {
    background: linear-gradient(#400080, transparent), linear-gradient(200deg, #d047d1, #ff0000, #ffff00);
}

[data-brand*="fa-facebook"] {
    background-color: #3b5998;
}

[data-brand*="fa-twitter"] {
    background-color: #1da1f2;
}

[data-brand*="fa-youtube"] {
    background-color: #ff0000;
}

[data-brand*="fa-linkedin"] {
    background-color: #0077b5;
}

[data-brand*="fa-google"] {
    background-color: #4285f4;
}

[data-brand*="fa-google-plus"] {
    background-color: #dd4b39;
}

.social-media>a:not([href]) {
    display: none !important
}

.owl-carousel {

    .owl-stage-outer {
        z-index: 1;

    }

    .owl-nav {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .owl-prev,
    .owl-next {
        z-index: 2;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate3d(0, -50%, 0);
        opacity: 1;
        transition: 0.4s ease;
        &:focus {
            outline: none;
        }

        &[class*="-next"] {
            right: 0;
            left: auto;
        }
    }

    .owl-dots {
        position: absolute;
        z-index: 2;
        left: 50%;
        transform: translate3d(-50%, 0, 0);
        bottom: 0;
        opacity: 1;

        .owl-dot {
            transition: 0.4s ease;
            &:focus {
                outline: none;
            }
            span {
                transition: 0.4s ease;
                &:focus {
                    outline: none;
                }
            }
        }
    }
}

.panel-image-overlay {
    transition: 0.3s ease;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.panel-image-overlay.gradient.bottom-right {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,1+100 */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMTAwJSI+CiAgICA8c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
    background: -moz-linear-gradient(-45deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    /* FF3.6-15 */
    background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 1)));
    /* Chrome4-9,Safari4-5 */
    background: -webkit-linear-gradient(-45deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: -o-linear-gradient(-45deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    /* Opera 11.10-11.50 */
    background: -ms-linear-gradient(-45deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    /* IE10 preview */
    background: linear-gradient(135deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#000000', GradientType=1);
    /* IE6-8 fallback on horizontal gradient */
}

.panel-image-overlay.gradient.bottom-left {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&1+0,0+100 */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMTAwJSIgeDI9IjEwMCUiIHkyPSIwJSI+CiAgICA8c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
    background: -moz-linear-gradient(45deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    /* FF3.6-15 */
    background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, rgba(0, 0, 0, 1)), color-stop(100%, rgba(0, 0, 0, 0)));
    /* Chrome4-9,Safari4-5 */
    background: -webkit-linear-gradient(45deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: -o-linear-gradient(45deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    /* Opera 11.10-11.50 */
    background: -ms-linear-gradient(45deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    /* IE10 preview */
    background: linear-gradient(45deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#00000000', GradientType=1);
    /* IE6-8 fallback on horizontal gradient */
}


.panel-image-overlay.gradient.top {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&1+0,0+100 */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMCIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    /* FF3.6-15 */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 1)), color-stop(100%, rgba(0, 0, 0, 0)));
    /* Chrome4-9,Safari4-5 */
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: -o-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    /* Opera 11.10-11.50 */
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    /* IE10 preview */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#00000000', GradientType=0);
    /* IE6-8 */
}

.container-fluid.container-max-width {
    max-width: map-get($container-max-widths, xl);
}

.sticky {
    position: relative;
    > div {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
    }
}

#pageOverlay, .modal-backdrop {
    background: $backdrop-color;
    position: fixed;
    z-index: 1048;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.4s ease;
}

.navbar-expand-xl {
    @include media-breakpoint-down(lg) {
        .modal-backdrop.show {
            opacity: 1;
            visibility: visible;
        }
    }
}
.navbar-expand-lg {
    @include media-breakpoint-down(md) {
        .modal-backdrop.show {
            opacity: 1;
            visibility: visible;
        }
    }
}
.navbar-expand-md {
    @include media-breakpoint-down(sm) {
        .modal-backdrop.show {
            opacity: 1;
            visibility: visible;
        }
    }
}
.navbar-expand-sm {
    @include media-breakpoint-down(xs) {
        .modal-backdrop.show {
            opacity: 1;
            visibility: visible;
        }
    }
}

.modal {
    transition: opacity 0.4s ease, visibility 0.4s ease;
    opacity: 0;
    visibility: hidden;
    display: block !important;

    .modal-dialog {
        transition: 0.4s ease;
        opacity: 0;
        transform: translate3d(0, -2.5rem, 0) !important;
    }
}
.mobile-nav-show #pageOverlay,
.modal.show {
    opacity: 1;
    visibility: visible;

    .modal-dialog {
        opacity: 1;
        transform: translate3d(0, 0, 0) !important;
    }
    + .modal-backdrop {
        opacity: 1;
        visibility: visible;
    }
}


html {
    .panel-image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 0;
        height: 100%;
        box-sizing: content-box;
        ~ * {
            position: relative;
        }
        video {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%,-50%,0);
        }
    }

    @include media-breakpoint-up(lg) {
        .parallax {
            overflow: hidden;
            position: relative;
        }

        .parallax .panel-image {
            transition: none !important;
            animation: none !important;
        }

        .parallax>.panel-image,
        .parallax .slides li>.panel-image {
            height: 100%;
            transition: 0 !important;
        }

        @media all and (max-width: map-get($grid-breakpoints, md) - 1) {

            // .parallax>.panel-image,
            // .parallax .slides li>.panel-image {
            // }

            .panel-image,
            .slides li>.panel-image {
                top: 0 !important;
                transform: none !important;
                padding: 0;
            }
        }

        &:not([data-browser-type*="InternetExplorer"]),
        &:not([data-browser-type*="Firefox"]),
        &:not([data-user-agent*="Edge"]) {

            .parallax>.panel-image {
                min-height: 100vh;
            }

            .parallax>.panel-image,
            .parallax .slides li>.panel-image {
                top: -50vh;
            }

            .parallax:first-child .slides li>.panel-image,
            .parallax:first-child .panel-image {
                top: 0;
            }

            .main-container>a:first-child+.parallax .panel-image {
                top: 0;
            }
        }

        &[data-user-agent*="Edge"],
        &[data-user-agent*="iPad"],
        &[data-user-agent*="iPhone"],
        &[data-user-agent*="Android"],
        &[data-browser-type*="Firefox"] {
            .panel-image {
                min-height: 1px;
                transform: none !important;
                top: initial !important;
                min-height: 1px !important;
            }

            .parallax>.panel-image,
            .parallax .slides li>.panel-image {
                top: 0;
            }
        }

        &[data-browser-type*="InternetExplorer"] {
            .modal {
                .modal-dialog-centered {
                    min-height: 100% !important;
                    margin-top: 0;
                    margin-bottom: 0;

                    .modal-content {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate3d(-50%, -50%, 0);
                    }
                }
            }
        }
    }
    @include media-breakpoint-down(md) {
        .parallax > .panel-image {
            transform: none !important;
            top: initial !important;
            min-height: 1px !important;
        }
    }
}

.ken-burns {
    animation: kenBurns 14s ease alternate infinite;
}

@keyframes kenBurns {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.1);
    }
}

// Apointment Request Form


.list-group-item-action {
    overflow: hidden;

    [type="radio"],
    [type="checkbox"] {
        display: none;

        &~label {
            display: block;
            margin: 0;
            padding: 0.5rem 1rem;
            cursor: pointer;
            transition: padding 0.2s ease;
        }

        &:checked~label {
            background: map-get($theme-colors, primary);

            @if (lightness(map-get($theme-colors, primary)) > 65) {
                color: map-get($theme-colors, dark);
            }

            @else {
                color: $white;
            }

            padding-left: 1.5rem;
        }
    }
}

[data-page-type="carecredit"] header,
[data-page-type="carecredit"] #quickLinks {
    background-color: map-get($theme-colors, dark) !important;
}

.faq-list {
    .list-group-item {
        background-color: transparent;
    }
}

@import "navbar";

footer {
    @include media-breakpoint-up(xl) {
        &.position-xl-fixed {
            bottom: 0; 
            left: 0; 
            right: 0; 
            z-index: -1;
        }
    }
    @include media-breakpoint-up(lg) {
        &.position-lg-fixed {
            bottom: 0; 
            left: 0; 
            right: 0; 
            z-index: -1;
        }
    }
    @include media-breakpoint-up(md) {
        &.position-md-fixed {
            bottom: 0; 
            left: 0; 
            right: 0; 
            z-index: -1;
        }
    }
    @include media-breakpoint-up(sm) {
        &.position-sm-fixed {
            bottom: 0; 
            left: 0; 
            right: 0; 
            z-index: -1;
        }
    }
}

@include media-breakpoint-up(xl) {
    .position-xl-absolute {
        position: absolute !important;
    }
    .position-xl-fixed {
        position: fixed !important;
    }
    .position-xl-relative {
        position: relative !important;
    }
}

@include media-breakpoint-up(lg) {
    .position-lg-absolute {
        position: absolute !important;
    }
    .position-lg-fixed {
        position: fixed !important;
    }
    .position-lg-relative {
        position: relative !important;
    }
}

@include media-breakpoint-up(md) {
    .position-md-absolute {
        position: absolute !important;
    }
    .position-md-fixed {
        position: fixed !important;
    }
    .position-md-relative {
        position: relative !important;
    }
}

@include media-breakpoint-up(sm) {
    .position-sm-absolute {
        position: absolute !important;
    }
    .position-sm-fixed {
        position: fixed !important;
    }
    .position-sm-relative {
        position: relative !important;
    }
}

@include media-breakpoint-up(xs) {
    .position-xs-absolute {
        position: absolute !important;
    }
    .position-xs-fixed {
        position: fixed !important;
    }
    .position-xs-relative {
        position: relative !important;
    }
}

[data-toggle="collapse"] {
    .if-open {
        display: inline-block;
    }
        .if-closed {
            display: none;
        }
    &.collapsed {
        .if-open {
            display: none;
        }
        .if-closed {
            display: inline-block;
        }
    }
}

[data-scroll-reveal-after] {
    opacity: 0;
    visibility: hidden;
    transition: 0.4s ease;
    &.show {
        opacity: 1;
        visibility: visible;
    }
}

.financing-page {
    &, > * {
        padding-top: 0 !important;
    }
}

@include media-breakpoint-down(sm) {
    .quick-contact-lite-layout-3 textarea {
        height: auto !important;
    }
}

/*========================= Media Queries ========================*/

@media (max-width: 1920px) {
    section.services-section .panel-image {
        background-position: 65% 0 !important;
    }
}

@include media-breakpoint-down(xl) {
    section.services-section .panel-image {
        background-position: 10% 0 !important;
    }
    // section.services-section .panel-image {
    //     opacity: 0.5;
    // }
    section.services-section .services-section-content {
        background: rgba(247,249,250,0.85);
        padding: 50px;
        margin: 0 15px;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
    }
    section.contact-form .mw {
        margin-right: 150px !important;
    }
}

@include media-breakpoint-down(lg) {
    section.contact-form .mw {
        margin-right: 0 !important;
    }
    section.main .mod1 {
        margin-left: 20px !important;
    }
    section.associations {
        padding: 0 0 100px;
    }
    section.tagline-section .top {
        font-size: 21px !important;
    }
    footer.foot .top {
        font-size: 21px;
    }
}

@include media-breakpoint-down(md) {
    section.services-section .panel-image {
        background-position: 35% 0 !important;
    }
    section.aaha .aaha-img  {
        margin: 0 auto;
    }
    section.main .mod1 {
        margin-left: 0 !important;
    }
    section.hours {
        padding: 100px 0 !important;
        margin: -10px 0 0 0;
    }
    section.contact-form iframe {
        height: 500px !important;
    }
    section.contact-form .mw {
        margin: 0 auto 100px !important;
    }
    section.services-section .w3 {
        float: left;
    }
    section.hero-section .logo {
        margin-top: 80px !important;
        width: 200px !important;
    }
    section.services-section .wrap {
        margin: 0 auto 30px !important;
    }
    footer.foot .info .mod {
        line-height: 34px;
        font-size: 18px;
    }
    footer.foot .info .mod span {
        display: block;
    }
    footer.foot .info .mod .separator {
        background: none;
        width: 0;
        height: 0;
    }
    nav.navbar .social {
        padding-left: 29px;
    }
    section.services-section .text-fx .mod {
        padding: 100px 0 !important;
    }

    section.tagline-section .top {
        line-height: 35px;
    }

    section.testimonials-section.testimonials .testimonial-wrapper {
        padding: 40px 15px 30px !important;
    }

    section.main .mainImg {
        padding: 25% 0;
        margin: 0 0 100px 0;
    }

    section.contact-form .mod {
        padding: 25% 0;
        margin: 0 0 100px 0;
    }

    section.associations {
        padding: 75px 0;
    }

    section.associations .hover-fx {
        width: 290px;
        margin: 0 auto 30px !important;
    }

    section.associations .d-flex {
        display: block !important;
    }

    footer.foot .mod {
        line-height: 35px;
    }
}

@include media-breakpoint-down(sm) {
    section.services-section .panel-image {
        background-position: 40% 0 !important;
    }
    section.hero-section .mod .display-2 {
        font-size: 33px !important;
        line-height: 45px !important;
    }
    section.services-section .w2, section.services-section .w3 {
        float: none !important;
    }
    section.hero-section .mod .max-w {
        max-width: 100% !important;
    }
    section.hero-section .logo {
        margin-top: 20px !important;
        width: 175px !important;
    }
    .galleryButtons .btn {
        margin-bottom: 5px;
    }
    section.contact-form .mod {
        margin: 0;
    }
    section.services-section {
        padding: 100px 0 !important;
    }
}

@media (max-width: 768px) {
    section.services-section {
        padding: 100px 0 !important;
    }
}

@include media-breakpoint-down(xs) {
    section.slider .mod {
        height: 600px;
    }

    section.slider .wrap {
        padding: 0 15px;
    }
}

@media (max-width: 320px) {
    section.hours .btn {
        padding: 12px 18px !important;
        font-size: 16px !important;
    }
    section.hours p {
        font-size: 14px !important;
        line-height: 28px !important;
    }
    section.hours h2 {
        font-size: 32px !important;
    }
    section.services-section .wrap {
        height: 250px !important;
        width: 250px !important;
        padding: 115px 0 0 !important;
    }
    section.hero-section .mod .display-2 {
        font-size: 26px !important;
        line-height: 36px !important;
    }
}
