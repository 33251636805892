/*
This is where you write custom SASS
*/

body[data-ajax-transitions="true"]
	#ajax-loading-screen[data-method="standard"]
.loading-icon {
    display: block;
    opacity: 1;
    transition: 0.3s ease;
}

#ajax-loading-screen {
	background-color: #fff;
	width: 100%;
	height: 100%;
	position: fixed;
	transition: 0.3s ease;
	top: 0;
	left: 0;
	display: none;
	z-index: 1000000000;
}

.loading-icon.spin,
.portfolio-loading.spin,
.pp_loaderIcon.spin,
.default-loading-icon.spin {
	animation:2s cubic-bezier(0.63,0.045,0.34,1) 0s normal none spinning_animation;
  -webkit-animation:2s cubic-bezier(0.63,0.045,0.34,1) 0s normal none spinning_animation;
}

#ajax-loading-screen .loading-icon {
	background-repeat: no-repeat;
	background-position: center center;
	display: block;
	position: absolute;
	opacity: 0;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

@keyframes spinning_animation {
	0%{
		transform:scale(1) rotate(0deg);
	}
	50%{
		transform:scale(0.8) rotate(360deg);
	}
	100%{
		transform:scale(1) rotate(720deg);
	}
}
