section.hero-section {
    min-height:400px;
    height: 1009px;

    .logo {
        margin-top: 160px;
    }
    .mod {
        min-height:400px;
        height: 1009px;

        .display-2 {
            font-size: 47px;
            font-style: italic;
            letter-spacing: 0.02em;
            line-height: 64px;
        }
        .max-w {
            max-width: 380px;
        }
        .btn {
            font-size: 21px;
            font-weight: 700;
            letter-spacing: 0.15em;
            padding: 15px 30px;
        }
    }
    @media screen and (max-width: 1600px) {
        & {
            height: 1009px;
        }
        .mod {
            height: 1009px;
        }
    }
    @media screen and (max-width: 991px) {
        & {
            height: 63vw;
        }
        .mod {
            height: 63vw;
        }
    }
}