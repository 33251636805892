
header.header-section {
    z-index: $zindex-fixed;
    
    // .text-white {
    //     color: rgba($color: #FFFFFF, $alpha: 0.5) !important;

    //     &:hover {
    //         color: rgba($color: #FFFFFF, $alpha: 1) !important;
    //     }
    // }
    &.scrolling .container-fluid.py-30px.py-lg-40px.bg-white {
        padding: 15px 15px !important;
    }
    
    .container-fluid.py-30px.py-lg-40px.bg-white {
        transition: .3s ease;   
    }
    
    @include media-breakpoint-down(lg) {
        .container {
            max-width: 100%;
        }
    }
    
    .phone-col {
        svg:not([class*="angle-down"]),
        i:not([class*="angle-down"]) {
            display: none !important;
        }
        ul[id*="rblPhones"] a{
            font-size: 12px !important;
            text-transform: uppercase;
            color: $primary !important;
            letter-spacing: 0.15em;
        }
        .phone-dropdown-menu ul, .address-dropdown-menu ul {
            padding: 0;
            margin: 0;
        }
        .phone-dropdown-menu ul a .row, 
        .address-dropdown-menu ul a .row {
            padding: .5rem .25rem .5rem .5rem;
            position: relative;
        }
        .phone-dropdown-menu ul a, 
        .address-dropdown-menu ul a {
            line-height: 1.4em;
            transition: 0.3s ease;
            opacity: 0;
            visibility: hidden;
            transform: translate3d(0, 1rem, 0);
            display: block;
        }
    }
}

@media screen and (min-width: 992px) {
    ul#desktopMenu {
        > li {
            > a {
                position: relative;
                &.current,
                &.highlighted,
                &:hover {
                    &:before {
                        background: $primary !important;
                        opacity: 1 !important;
                    }
                }
                &:before {
                    content: '';
                    position: absolute;
                    transition: .3s ease;
                    bottom: 0;
                    left: 5px;
                    right: 5px;
                    height: 2px;
                    border-radius: 0;
                    background: transparent;
                    opacity: 0;
                }
            }
            > a.has-submenu {
                position: relative;
                &.highlighted,
                &:hover {
                    &:before {
                        background: $primary !important;
                        opacity: 1 !important;
                    }
                }
                &:before {
                    content: '';
                    position: absolute;
                    transition: .3s ease;
                    bottom: 0;
                    left: 5px;
                    right: 5px;
                    height: 2px !important;
                    border-radius: 0;
                    background: transparent;
                    opacity: 0;
                }
            }
        }
    }
}