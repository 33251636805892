
section.services-section {
    padding: 175px 0 125px;

    .wrap {
        background-color: rgba($color: #B72525, $alpha: 0.85);
        border-radius: 50%;
        height: 290px;
        width: 290px;
        padding: 135px 0 0;

        .h5 {
            font-family: lato, sans-serif;
            color: #ffffff;
            font-weight: 700;
        }
    }
    a:hover {
        .wrap {
            background-color: rgba($color: #B72525, $alpha: 1);
        }
    }
    .w2 {
        float: right;
    }
}