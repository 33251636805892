section.testimonials-section {
    background-color: #1592E6;

&.testimonials {
    blockquote {
        margin: 0 0 2rem;

        p {
            font-family: 'ivypresto-text', serif !important;
            @include media-breakpoint-up(xxl) {
                & {
                    font-size: 27px;
                    line-height: 34px;
                }
            }
            @include media-breakpoint-down(xxl) {
                font-size: 27px;
                line-height: 34px;
            }
        }

        a {
            color: #fff;

            &:hover {
                color: #d9d9d9;
            }
        }

        cite {
            :nth-child(2) {
                display: none !important;
            }
            :nth-child(3) {
                display: none !important;
            }
        }
        

        footer {
            cite {
                span {
                    letter-spacing: 0.2em !important;
                }
            }
        }
    }
    .testimonial-wrapper {
        border-right: none !important;
        @media screen and (max-width: 991px) {
            & {
                margin-bottom: 0px !important;
                margin-top: 0 !important;
            }
        }
        @include media-breakpoint-up(xxl) {
            & {
                padding: 40px 0 30px;
            }
        }
        @media screen and (max-width: 1720px) {
            & {
                padding: 40px 0 30px;
            }

        }
        @include media-breakpoint-down(xl) {
            & {
                padding: 40px 0 30px;
            }
        }
        @include media-breakpoint-down(sm) {
            & {
                padding: 40px 0 30px;
            }
        }

    }
}
    
}